import React, { useEffect, useState, useRef } from "react";
import Feedback from "../../Components/Feedback/Feedback";
import $ from 'jquery'
import { cloneDeep } from "lodash";

import NavBar from "../../Components/NavBar/NavBar";
import SearchCheckboxGroup from "../../Components/SearchCheckboxGroup/SearchCheckboxGroup";
import TabGroup from "../../Components/TabGroup/TabGroup";
import SearchResult from "../../Components/SearchResult/SearchResult";
import SearchTagList from "../../Components/SearchTagList/SearchTagList";

import './SearchPage.css';

import productJSON from '../../Data/products.json';
import tocJSON from '../../Data/toc.json';

const SearchPage = () => {

    // eslint-disable-next-line no-unused-vars
    const [tabList, setTabList] = useState({
        'On-premises': {'title': 'Software', 'value': false, 'count': 999},
        'SaaS':{'title': 'SaaS', 'value': false, 'count': 999},
    })

    const [filterData, setFilterData] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [validCheckboxCount, setValidCheckboxCount] = useState(0);
    const [altCheckboxCount, setAltCheckboxCount] = useState(0);
    const [appliedFilters, setAppliedFilters] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [deviceTypeName, setDeviceTypeName] = useState('desktop');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(10);

    const searchInfoRef = useRef();
    const mainSearchContentRef = useRef();

    function escapeRegex(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    // Search configuration
    const search_componentConfig = {
        div: "gcse-searchbox",
        tag: "searchbox",
        gname: "ibm-webmethods-doc-search"
    }

    const searchresults_componentConfig = {
        div: "gcse-searchresults",
        tag: "searchresults",
        gname: "ibm-webmethods-doc-search"
    }

    const gcsInitialCallback = function () {
        if (document.readyState === 'complete') {
            // Document is ready when Search Element is initialized.
            window.google.search.cse.element.render(search_componentConfig, searchresults_componentConfig);
        } else {
            // Document is not ready yet, when Search Element is initialized.
            window.google.setOnLoadCallback(function () {
                window.google.search.cse.element.render(search_componentConfig, searchresults_componentConfig);
            }, true);
        }
    };

    // Search Starting callback to add the filters
    const gcsWebSearchStartingCallback = (gname, query) => {
        
        let filterString = getSearchFilterString();

        let url = new URL(window.location.href)
        if (url.searchParams.get('q') !== query) {
            url.searchParams.set('q', query);
            window.history.pushState({}, '', url.href);

            searchInfoRef.current.textContent = ` "${query}" in all IBM webMethods Documentation`
        }

        query = filterString ? query + ' ' + filterString : query;
        return query;
    };

    const getProductMetaData = (url) => {
        let metaDataString = '';

        const saasURLS = {
            // "/integration":"webMethods Integration",
            // "/embed":"webMethods Embed",
            // "/b2b":"webMethods B2B",
            // "/b2bapp":"webMethods B2B",
            // "/mft":"webMethods Managed File Transfer",
            // "/cloudcontainer":"webMethods Cloud Container",
            "/developerportal":"webMethods Developer Portal",
            // "/softwareagcloud":"webMethods iPaaS",
            // "/e2e":"webMethods End-to-End Monitoring",
            // "/apicontrolplane":"webMethods.io API Control Plane",
            // "/wpm":"webMethods Package Registry",
            // "/wmioinfra":"Private Links",
            "/apigateway":"webMethods API Gateway"
        }

        
        // filtering through products to get those with matching baseURL
        let productMatch = Object.keys(productJSON).filter(prodKey => {
            return url.startsWith(`https://docs.webmethods.io${productJSON[prodKey].baseURL}`);
        });

        // finding the longest match
        productMatch.sort((a,b) => productJSON[b].baseURL.length - productJSON[a].baseURL.length)

        // Check if the url has the older SaaS products
        let oldSaaSMatch = Object.keys(saasURLS).filter(u => {
            return url.startsWith(`https://docs.webmethods.io${u}`);
        });

        // finding the longest match
        oldSaaSMatch.sort((a,b) => a.length - b.length);

        if(productMatch.length || url.match('/on-premises/wmsuites/')){
            let resultProdKey = '';
            let productName = ''
            let productBaseURL = '';
            let platform = '';

            if(productMatch.length){
                resultProdKey = productMatch[0];
                productName = productJSON[resultProdKey].title;
                productBaseURL = productJSON[resultProdKey].baseURL;
                platform = tabList[productJSON[resultProdKey].deployment].title;
            }
            else{
                productBaseURL = '/on-premises/wmsuites';
                productName = 'Cross Products and Guides'
                platform = 'Software';
            }

            // let language = url.match('/ja/') && url.match('/ja/') ? 'Japanese' : 'English';
            let regexVal = new RegExp(`https://docs.webmethods.io${productBaseURL}/(en/)?`);
            let extractedVersion = url.replace(regexVal,'').split('/')[0];

            let cleanedVersion = extractedVersion.match(/[0-9]+(\.[0-9]+)*/g);
            let version = cleanedVersion && cleanedVersion[0].length === extractedVersion.length ? extractedVersion : ''

            if(resultProdKey === 'YAI' && (extractedVersion === '10.15.0' || extractedVersion === '11.0.0')){
                productName = "webMethods.io API Gateway"                
            }

            metaDataString =  platform ? `<span class="metadata-item">${platform}</span>` : '';
            metaDataString += productName? `<span class="metadata-item">${productName}</span>` : '';
            metaDataString += version ? `<span class="metadata-item">${version}</span>` : '';
            // metaDataString += language ? `<span class="metadata-item">${language}</span>`: '';
        }
        else if(url.startsWith(`https://docs.webmethods.io/apigateway`)){
            productMatch = ['YAI'];
            let extractedVersion = url.replace(`https://docs.webmethods.io/apigateway/`,'').split('/')[0];
            
            metaDataString = `<span class="metadata-item">SaaS</span>`;
            metaDataString += `<span class="metadata-item">webmethods.io API Gateway</span>`;
            metaDataString += `<span class="metadata-item">${extractedVersion}</span>`;
            // metaDataString += `<span class="metadata-item">English</span>`;
        }
        else if(url.startsWith(`https://docs.webmethods.io/developerportal`)){
            productMatch = ['YAI'];
            let extractedVersion = url.replace(`https://docs.webmethods.io/developerportal/`,'').split('/')[0];

            metaDataString = `<span class="metadata-item">SaaS</span>`;
            metaDataString += `<span class="metadata-item">webmethods.io Developer Portal</span>`;
            metaDataString += `<span class="metadata-item">${extractedVersion}</span>`;
            // metaDataString += `<span class="metadata-item">English</span>`;
        }
        else if(oldSaaSMatch){
            let resultProdKey = oldSaaSMatch[0];
            let productName = saasURLS[resultProdKey];

            metaDataString = `<span class="metadata-item">SaaS</span>`;
            metaDataString += `<span class="metadata-item">${productName}</span>`
            // metaDataString += `<span class="metadata-item">English</span>`
        }

        return metaDataString;
    }

    const getSearchQuery = () => {
        const url = new URL(window.location.href);
        const query = url.searchParams.get('q');
        return query;
    }

    function  gcsResultsReadyCallback(name, q, promos, results, resultsDiv) {
        
        const newSearchResults = [];

        if (results.length) {
            results.forEach((result,idx) => {
                const actualUrl = new URL(decodeURIComponent(result.url));
                const actualLink = actualUrl.searchParams.get('q');
                const metadata = getProductMetaData(actualLink);
                // const metadata = '';

                newSearchResults.push(
                    <SearchResult
                        key={`${actualLink}-${idx}`}
                        title={result.title}
                        actualUrl={actualUrl}
                        actualLink={actualLink}
                        metadata={metadata}
                        description={result.content}
                    />
                )
            });

            setTimeout(() => {
                const pageCount = $('.gsc-results .gsc-cursor-box .gsc-cursor .gsc-cursor-page').length;
                const page = $('.gsc-cursor-page.gsc-cursor-current-page').length ? $('.gsc-cursor-page.gsc-cursor-current-page').text() : '1';

                setTotalPageCount(pageCount);
                setCurrentPage(Number(page));
            }, 100);
        }
        // return true to indicate results rendering will be explictly handled
        setSearchResults(cloneDeep(newSearchResults));
        return true;
    };

    function searchExecuteHandler(query=''){
        if(window.google.search){
            let gscElement = window.google.search.cse.element.getElement('ibm-webmethods-doc-search');
            if(query){
                gscElement.execute(query);
            }
            else{
                gscElement.execute();
            }
        }
    }

    function applySearchHandler(){
        searchExecuteHandler();
        const newAppliedFilters = [];
        
        Object.keys(filterData).forEach(pid => {
            Object.keys(filterData[pid].childData).forEach((prod_id) => {
                if(filterData[pid].childData[prod_id].childCheckState){
                    const childkey = filterData[pid].childData[prod_id].id;
                    if(!newAppliedFilters.includes(childkey)){
                        newAppliedFilters.push(childkey);
                    }
                }
            })
        });

        toggleFilterHandler();
        setAppliedFilters([...newAppliedFilters]);
    }

    function resetBtnClickHandler(){        
        const newFilterData = cloneDeep(filterData);
        Object.keys(newFilterData).forEach(pid => {
            newFilterData[pid].parentCheckState = false;
            Object.keys(newFilterData[pid].childData).forEach((product_id) => {
                if(newFilterData[pid].childData[product_id].childCheckState){
                    newFilterData[pid].childData[product_id].childCheckState = false;
                }
            })
        });


        let url = new URL(window.location.href)
        let query = url.searchParams.get('q');

        setFilterData(cloneDeep(newFilterData));
        setAppliedFilters([]);
        setTimeout(() => searchExecuteHandler(query),100);
    }

    function getSearchFilterString(){
        const domain = 'docs.webmethods.io';
        const checkboxList = $('.checkbox-children input:checked');
        const urlList = [];

        checkboxList.each((ch,el) => {
            $(el).prop('value').split(' ').forEach(url => {
                if(!urlList.includes(`site:${domain}${url}`)){
                    urlList.push(`site:${domain}${url}`);
                }
            })
        });


        if(urlList.includes(`site:${domain}/on-premises/webmethods-adapters`)){
            urlList.push(
                `site:${domain}/on-premises/webmethods-websphere-mq-adapter`,
                `site:${domain}/on-premises/webmethods-adapter-for-jdbc`,
                `site:${domain}/on-premises/webmethods-adapter-for-mongodb`,
                `site:${domain}/on-premises/webmethods-adapter-for-opc`,
                `site:${domain}/on-premises/webmethods-adapter-for-apache-kafka`,
                `site:${domain}/on-premises/webmethods-adapter-for-ibm-power`,
                `site:${domain}/on-premises/webmethods-adapter-for-salesforce`,
                `site:${domain}/on-premises/webmethods-adapter-for-sap`,
            )
        }

        if(urlList.includes(`site:${domain}/on-premises/webmethods-estandards`)){
            urlList.push(
                `site:${domain}/on-premises/webmethods-module-for-ach`,
                `site:${domain}/on-premises/webmethods-module-for-as4`,
                `site:${domain}/on-premises/webmethods-module-for-chem-estandards`,
                `site:${domain}/on-premises/webmethods-module-for-edi`,
                `site:${domain}/on-premises/webmethods-module-for-fix`,
                `site:${domain}/on-premises/webmethods-module-for-swift`,
                `site:${domain}/on-premises/webmethods-module-for-1sync`,
                `site:${domain}/on-premises/webmethods-module-for-ediint`,
                `site:${domain}/on-premises/webmethods-module-for-ebxml`,
                `site:${domain}/on-premises/webmethods-module-for-flatfile`,
                `site:${domain}/on-premises/webmethods-module-for-iso-8583`,
                `site:${domain}/on-premises/webmethods-module-for-hl7`,
                `site:${domain}/on-premises/webmethods-module-for-hipaa`,
                `site:${domain}/on-premises/webmethods-module-for-rosettanet`,
            )
        }

        if(urlList.includes(`site:${domain}/saas/webmethods-api-gateway`)){
            urlList.push(
                `site:${domain}/apigateway`,
            )
        }

        if(urlList.includes(`site:${domain}/saas/webmethods-developer-portal`)){
            urlList.push(
                `site:${domain}/developerportal`,
            )
        }

        const filterString = urlList.join(' OR ');

        return filterString;
    }

    const gscElementObserverCallback =  async() => {
        document.querySelectorAll('#___gcse_0 + #___gcse_0').forEach(x => x.remove());
    }

    // Google Search Setup
    useEffect(() => {
        // Load Google Custom Search Engine script
        const script = document.createElement("script");
        script.src = "https://cse.google.com/cse.js?cx=645f3b5256f74472b";
        script.async = true;
        document.body.appendChild(script);

        // Initializing google search element
        window.__gcse = {
            parsetags: 'explicit',
            initializationCallback: gcsInitialCallback,
        };

        // Install this callbacks in window.__gcse:
        window.__gcse || (window.__gcse = {});
        window.__gcse.searchCallbacks = {
            web: {
                starting: gcsWebSearchStartingCallback,
                ready: gcsResultsReadyCallback
                // rendered: myResultsRenderedCallback
            },
        };

        const observer = new MutationObserver(gscElementObserverCallback);
        const targetNode = document.querySelector('#gcse-searchbox');
        const config = { childList: true, subtree: false };
        observer.observe(targetNode, config);

        let url = new URL(window.location.href)
        let q = url.searchParams.get('q');
        if (q) {
            searchInfoRef.current.textContent = ` "${q}" in all IBM webMethods Documentation`
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Choosing a deafult tab
    useEffect(() => {
        setTabList({
            'On-premises': {'title': 'Software', 'value': true, 'count': 999},
            'SaaS':{'title': 'SaaS', 'value': false, 'count': 999},
        })
    },[])

    // UseEffect to build checkbox list based on tab selection
    useEffect(() => {
        const data = {};
        const tocProdMapping = {}
        let validCount = 0;
        let altCount = 0;
        
        // Add toc data for parent checkboxes
        Object.keys(tocJSON).forEach(tocId => {
            const tocObject = tocJSON[tocId];
            data[tocId] = {
                "id":tocObject.key,
                "label":tocObject.title,
                "value":tocObject.key,
                "childData":[],
                "parentCheckState":false
            }

            tocObject.capability_mapping.forEach(cid => {
                tocProdMapping[cid] = tocId;
            });
        });

        // Add product data for child checkboxes
        Object.keys(productJSON).filter(a => productJSON[a]['product_card_active'] === 'Y').forEach(prodCode => {
            const product = productJSON[prodCode];
            const parentTocItem = [];
            
            product.capabilityId.forEach(cid => {
                if(!parentTocItem.includes(tocProdMapping[cid])){
                    parentTocItem.push(tocProdMapping[cid]);
                }
            })
            
            // const parentTocItem = tocProdMapping[product.capabilityId];

            let tabValid = tabList[product.deployment].value;
            let searchValid = true;

            // Checking if the search term is present in the product title
            searchValue.split(' ').forEach(searchTerm => {
                const escapedSearchTerm = escapeRegex(searchTerm);
                if(!Boolean(product["search_string"].match(new RegExp(escapedSearchTerm,'ig')))){
                    searchValid = searchValid && false;
                }
            })

            if(tabValid){
                if(searchValue){
                    parentTocItem.forEach(tid => {
                        data[tid].expanded = true;
                    })
                }
                else{
                    parentTocItem.forEach(tid => {
                        data[tid].expanded = false;
                    })
                }

                parentTocItem.forEach(tid => {
                    data[tid].childData.push({
                        "id":prodCode,
                        "label":product.title,
                        "value":product.baseURL,
                        "childCheckState":false,
                        "searchValid": searchValid,
                        "capabilityId":parentTocItem
                    });
                })

                if(searchValid){
                    validCount += 1;
                }
            }
            else if(!tabValid && searchValid){
                altCount += 1;
            }
        });

        setValidCheckboxCount(validCount);
        setAltCheckboxCount(altCount);
        setFilterData(cloneDeep(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tabList]);


    // UseEffect to hide/reveal checkboxes based on filter value
    useEffect(() => {
        const data = cloneDeep(filterData);
        let validCount = 0;

        // Updating searchValid value for products
        Object.keys(data).forEach(pid => {
            const pidObj = data[pid];

            Object.keys(pidObj.childData).forEach(prodkey => {
                
                const product = data[pid].childData[prodkey];
                // const sear
                let searchValid = true;
                let search_string = productJSON[product.id]["search_string"];

                // Checking if the search term is present in the product title
                searchValue.split(' ').forEach(searchTerm => {
                    const escapedSearchTerm = escapeRegex(searchTerm);
                    if(!Boolean(search_string.match(new RegExp(escapedSearchTerm,'ig')))){
                        searchValid = searchValid && false;
                    }
                });

                data[pid].childData[prodkey].searchValid = searchValid;

                if(searchValid){
                    validCount += 1;
                }

            });
        });

        // Calculating alternate searchCount
        let altCount = 0;
        let altTab = Object.keys(tabList).filter(tabKey => !tabList[tabKey].value)[0];
        Object.keys(productJSON).filter(productKey => productJSON[productKey]["product_card_active"] === 'Y').forEach(prodkey => {
            const product = productJSON[prodkey];
            if(product.deployment === altTab){
                let searchValid = true;

                // Checking if the search term is present in the product title
                searchValue.split(' ').forEach(searchTerm => {
                    const escapedSearchTerm = escapeRegex(searchTerm);
                    if(!Boolean(product["search_string"].match(new RegExp(escapedSearchTerm,'ig')))){
                        searchValid = searchValid && false;
                    }
                });

                if(searchValid){
                    altCount += 1;
                }
            }
        });


        setValidCheckboxCount(validCount);
        setAltCheckboxCount(altCount);
        setFilterData(cloneDeep(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchValue]);

    // Element generation functions
    const generateProductCheckboxes = () => {
        const productCheckboxes = [];
        const toc_keys = Object.keys(filterData).sort((a,b) => Number(a) - Number(b));
        
        toc_keys.forEach(tid => {
            const tocItem = filterData[String(tid)];
            const validChildCheckboxCount = Object.keys(tocItem.childData).filter(cid => tocItem.childData[cid].searchValid).length;
            
            // const deployment = validChildCheckboxCount ? productJSON[tocItem.childData[0].id].deployment : 'SaaS';
            productCheckboxes.push(
                <SearchCheckboxGroup 
                    key={`product-checkbox-${tid}`}
                    key_id={tid}
                    id={tocItem.id}
                    label={tocItem.label}
                    value={tocItem.id}
                    childData={tocItem.childData}
                    setFilterData={setFilterData}
                    filterData={filterData}
                    searchValue={searchValue}
                    defaultExpand={searchValue ? true : false}
                    validChildCheckboxCount={validChildCheckboxCount}
                />
            )
        })

        return productCheckboxes;
    }

    // Event Listener
    const tabClickHandler = (id) => {
        const newTabStates = {...tabList};
        Object.keys(newTabStates).forEach(key => {
            newTabStates[key].value = false;
        })

        newTabStates[id].value = true;
        setTabList(cloneDeep(newTabStates));
        setAppliedFilters([]);

        // Re-executing search
        setTimeout(() => {
            let url = new URL(window.location.href)
            let query = url.searchParams.get('q');

            if (query && window.google && window.google.search){
                let gscElement = window.google.search.cse.element.getElement('ibm-webmethods-doc-search');
                gscElement.execute(query);
            }
        },100);
    }

    const searchTagCloseHandler = (prod_id) => {

        // Updating filter data
        const newFilterData = cloneDeep(filterData);

        Object.keys(newFilterData).forEach(fid => {
            newFilterData[fid].childData.forEach(child => {
                if(child.id === prod_id){
                    child.childCheckState = false;
                }
            })
        });

        // Updating filter tags
        const newAppliedFilters = [...appliedFilters];
        const idx = newAppliedFilters.indexOf(prod_id);
        if(idx >= 0){
            newAppliedFilters.splice(idx,1);
        }

        // Updating state variables
        setAppliedFilters([...newAppliedFilters]);
        setFilterData(cloneDeep(newFilterData));

        // Re-executing search
        setTimeout(() => {
            let url = new URL(window.location.href)
            let query = url.searchParams.get('q');

            if (query && window.google && window.google.search){
                let gscElement = window.google.search.cse.element.getElement('ibm-webmethods-doc-search');
                gscElement.execute(query);
            }
        },100);
    }

    const toggleFilterHandler = () => {
        if(mainSearchContentRef.current){
            mainSearchContentRef.current.classList.toggle('filter-expand');
        }
    }

    const pageClickHandler = (destPage) => {
        const destPageElement = $(`.gsc-cursor-page[aria-label="Page ${destPage}"]`);
        if(destPageElement){
            destPageElement.trigger('click');
        }
        
    }

    function deviceType()  {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    };

    useEffect(() => {
        const d = deviceType();
        setDeviceTypeName(d);
        if(d !== "desktop"){
            $('body').addClass('mobile');
        }
    },[])

    return(
       <React.Fragment>
            <style>
                {
                    `
                        @media screen and (max-width:655px) {
                            body {
                                /*position: fixed;*/
                                top: 0px;
                                left: 0px;
                            }
                        }
                    `
                }
            </style>
            <NavBar noSearch={true}/>
            <div className="main-search-content" ref={mainSearchContentRef}>
                <section className="filter-section">
                    <div className="filter-section-header">
                        <h3>Refine Results</h3>
                        <button className="toggle-filter-btn" onClick={toggleFilterHandler}>
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="12.6923" height="12.6923" transform="translate(0.561523 0.144531)" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                                <path d="M10.0815 3.8717L9.52623 3.31641L6.90844 5.9342L4.29064 3.31641L3.73535 3.8717L6.35315 6.48949L3.73535 9.10729L4.29064 9.66258L6.90844 7.04478L9.52623 9.66258L10.0815 9.10729L7.46373 6.48949L10.0815 3.8717Z" fill="#161616"/>
                            </svg>
                        </button>
                    </div>
                    <TabGroup tabList={tabList} tabClickHandler={tabClickHandler}/>
                    <div className="checkbox-input-wrapper">
                        <div className="checkbox-input-container">
                            <i>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12H5C4.73478 12 4.48043 11.8946 4.29289 11.7071C4.10536 11.5196 4 11.2652 4 11V7.205L0.295 3.5C0.107206 3.31332 0.00111639 3.05979 0 2.795V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H11C11.2652 0 11.5196 0.105357 11.7071 0.292893C11.8946 0.48043 12 0.734784 12 1V2.795C11.9989 3.05979 11.8928 3.31332 11.705 3.5L8 7.205V11C8 11.2652 7.89464 11.5196 7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12ZM1 1V2.795L5 6.795V11H7V6.795L11 2.795V1H1Z" fill="#161616"/>
                                </svg>
                            </i>
                            <input 
                                placeholder="Filter"
                                onInput={event => setSearchValue(event.target.value)}
                                value={searchValue ? searchValue : ''}
                            />
                            {searchValue && (
                                <button 
                                title='Clear'
                                className="clear-searchbox-button" 
                                onClick={() => setSearchValue('')} 
                                aria-label="Clear Search"
                                >
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.38381 5L10.5 0.883812L9.61619 0L5.5 4.11619L1.38394 0L0.5 0.883812L4.61619 5L0.5 9.11619L1.38394 10L5.5 5.88381L9.61619 10L10.5 9.11619L6.38381 5Z" fill="#525252"/>
                                </svg>

                                </button>
                            )}
                        </div>
                    </div>
                    <span className="checkbox-searchbox-result">
                        {
                            searchValue ?
                            <React.Fragment>
                                <span className="filter-info-item">
                                    <span className="search-count">
                                        {validCheckboxCount} results
                                    </span> 
                                    &nbsp;for "{searchValue}" in {tabList[Object.keys(tabList).filter(tabKey => tabList[tabKey].value)[0]].title}
                                </span>
                                {/* <React.Fragment> */}
                                {
                                    altCheckboxCount ?
                                    <React.Fragment>
                                        <span className='slash'>/</span>
                                        <span className="filter-info-item">
                                            <span className='alt-result' onClick={() => tabClickHandler(Object.keys(tabList).filter(tabKey => !tabList[tabKey].value)[0])}>
                                            {altCheckboxCount} more
                                            </span> 
                                            {' '} found in {tabList[Object.keys(tabList).filter(tabKey => !tabList[tabKey].value)[0]].title}
                                        </span>
                                    </React.Fragment>
                                    : ''
                                }
                                {/* </React.Fragment> */}
                            </React.Fragment>
                        : ''
                        }
                    </span>
                    <div className="checkbox-list">
                        {[...generateProductCheckboxes()]}
                    </div>
                    <div className="actions-container">
                        <button className="filter-reset-btn button-tertiary" onClick={resetBtnClickHandler}>Reset</button>
                        <button className="filter-apply-btn button-primary" onClick={applySearchHandler}>Apply</button>
                    </div>
                </section>
                <section className="search-results-section">
                    <div className="results-section-header">
                        <button className="open-filter-btn button-tertiary" onClick={toggleFilterHandler}>
                            Filter
                        </button>
                        <h3>
                            <strong>Search Results for</strong><span ref={searchInfoRef}> No search made.</span>
                        </h3>
                    </div>
                    <SearchTagList 
                        appliedFilters={appliedFilters} 
                        searchTagCloseHandler={searchTagCloseHandler}
                    />
                    <div id="gcse-searchresults" className={[searchResults.length ? "" : "no-results"]}>
                        {
                            searchResults.length ?
                            [...searchResults]
                            :
                            <div className="no-search-results-container">
                                <span>
                                    {
                                        getSearchQuery() === null || getSearchQuery() === '' ?
                                        "Use the search bar to query IBM webMethods Documentation"
                                        :
                                        `No search results found for "${getSearchQuery()}". Please check your spellings and filters`
                                    }
                                </span>
                            </div>
                        }
                    </div>
                    {
                        searchResults.length ?
                        <div className="pagination-container">
                            {
                                currentPage > 1 ?
                                <button className="change-page-btn prev-page" onClick={() => pageClickHandler(currentPage - 1)}>
                                    <svg width="16" height="16" viewBox="0 -2 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <title>View previous page</title>
                                        <rect width="16" height="16" transform="translate(0 -3.5)" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                                        <path d="M8 1.5L13 6.5L12.3 7.2L8 2.9L3.7 7.2L3 6.5L8 1.5Z" fill="#525252"/>
                                    </svg>
                                </button>
                                : ''
                            }
                            <span className="current-page">Page {currentPage}</span>
                            {
                                currentPage < totalPageCount ?
                                <button className="change-page-btn next-page" onClick={() => pageClickHandler(currentPage + 1)}>
                                    <svg width="16" height="16" viewBox="0 -2 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <title>View next page</title>
                                        <rect width="16" height="16" transform="translate(0 -3.5)" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                                        <path d="M8 1.5L13 6.5L12.3 7.2L8 2.9L3.7 7.2L3 6.5L8 1.5Z" fill="#525252"/>
                                    </svg>
                                </button>:''
                            }
                        </div>
                        :''
                    }
                </section>
            </div>
            <Feedback/>
       </React.Fragment>
    )
}

export default SearchPage;