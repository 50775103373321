import React,{useState, useEffect, useRef} from "react";
import { cloneDeep } from "lodash";
import $ from 'jquery';

import ProductDocSection from "../ProductDocSection/ProductDocSection";
import topicCategoriesJSON from '../../Data/topic-categories.json';
import DropdownVersion from "../DropdownVersion/DropdownVersion";
import DropdownLang from "../DropdownLang/DropdownLang";

import './ProductContent.css';

import productJSON from '../../Data/products.json';
import topicsJSON from '../../Data/topics.json';

const ProductContent = (props) => {
    
    // Data state variables
    // eslint-disable-next-line no-unused-vars
    const [categoriesData, setCategoriesData] = useState(topicCategoriesJSON);
    const [topicData, setTopicData] = useState({});

    // right toc variables
    const right_toc_elts = useRef(null);
    const right_toc_count = useRef(0);
    // const onScrollActive = useRef(true);

    // Filter state variables
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [urlHash, setUrlHash] = useState('');

    // Function to add a hashchange event listener and
    // update the urHash state on page's first load
    useEffect(() => {
        
        // Logic for handling hash changes
        const handleHashChange = () => {
            const newHash = window.location.hash.split('#')[1];
            setUrlHash(newHash);
        }
        window.addEventListener('hashchange',handleHashChange);

        // Updating the urlHash on initial page load
        const newHash = window.location.hash.split('#')[1];
        if(newHash){
            setUrlHash(newHash);

            const titleElt = document.getElementById(newHash);
            if(titleElt){
                titleElt.scrollIntoView();
            }
        }
        else{
            setUrlHash('');
        }

        // Logic for updating the url hash on page scroll
        function updateHash_scrollHandler(){
            if(!props.rightTocRef || !props.rightTocRef.current){
                return;
            }

            console.log(props.rightTocRef.current.classList.contains('no-scroll'));

            if(props.rightTocRef.current.classList.contains('no-scroll')){
                return;
            }

            let activeTocHash = null;
    
            if(right_toc_elts == null || !right_toc_elts.length){
                right_toc_elts.current = document.querySelectorAll('.right-toc-item  a');
            }

            right_toc_elts.current.forEach((elt, _idx) => {
                // console.log(elt);
                const hash = elt.getAttribute('href');
                const hashName = hash.split('#')[1];
                const headerSelector = `#${hashName}`;
                const headerElt = document.querySelector(headerSelector);
                
                if(headerElt){
                    const topVal = headerElt.getBoundingClientRect().top;
                    const ACTIVE_TOC_THRESHOLD = 450;
    
                    if(topVal <= ACTIVE_TOC_THRESHOLD){
                        activeTocHash = hashName;
                    }
                }
            });
    
            if(activeTocHash){
                const url = new URL(window.location);
                url.hash = activeTocHash;
                window.history.replaceState({}, "", url);
                setUrlHash(activeTocHash);
            }
        }
        window.addEventListener('scroll',updateHash_scrollHandler);

        // Setting topicData for initial page load
        if(props.selectedProduct in productJSON && props.selectedVersion && props.selectedLanguage){
            let catId = productJSON[props.selectedProduct]['active_topic_categories'][0];
            setSelectedCategory(catId);
            setTopicData(productJSON[props.selectedProduct]['content'][`${props.selectedVersion}-${props.selectedLanguage}`]);
        }

        // Cleanup on unmount
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
            window.removeEventListener("hashchange", updateHash_scrollHandler);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.selectedVersion, props.selectedLanguage]);

    useEffect(() => {
        if(urlHash){
            let categoryId = Object.keys(categoriesData).find(idx => categoriesData[idx].key === urlHash);
            if(categoryId){
                setSelectedCategory(categoryId ? categoryId : productJSON[props.selectedProduct]['active_topic_categories'][0]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlHash]);

    // Use effect to populate topic card data when page state changes
    useEffect(() => {
        const filteredTopicList = {};
        const isProduct = props.selectedProduct && props.selectedProduct in productJSON;
        const isVersion = props.selectedVersion;
        const isLanguage = props.selectedLanguage;

        if(isProduct && isVersion && isLanguage){
            let contentData = {};
            const active_topic_categories = productJSON[props.selectedProduct]['active_topic_categories'];
            
            if(`${props.selectedVersion}-${props.selectedLanguage}` in productJSON[props.selectedProduct]['content']){
                contentData = productJSON[props.selectedProduct]['content'][`${props.selectedVersion}-${props.selectedLanguage}`];
            }
    
            active_topic_categories.forEach(catId => {
                filteredTopicList[catId] = [];
                if(catId in contentData){
                    contentData[catId].forEach(topicKey => {
                        let topicObj = topicsJSON[topicKey];
                        let searchValid = true;
        
                        searchValue.split(' ').forEach(searchTerm => {
                            const escapedSearchTerm = escapeRegex(searchTerm);
                            if(!Boolean(topicObj.title.match(new RegExp(escapedSearchTerm,'ig')))){
                                searchValid = searchValid && false;
                            }
                        })
        
                        if(searchValid){
                            filteredTopicList[catId].push(topicKey);
                        }
                    });
                }
            });
  
        }
        
        setTopicData(cloneDeep(filteredTopicList));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCategory,searchValue, props.selectedProduct, props.selectedVersion, urlHash]);

    // Generate documentation sections
    const generateDocSections = () => {
        let docList = [];

        if(!props.selectedProduct || !(props.selectedProduct in productJSON)){
            return docList;
        }

        if(!props.selectedVersion || !props.selectedLanguage || !(`${props.selectedVersion}-${props.selectedLanguage}` in productJSON[props.selectedProduct]['content'])){
            return docList;
        }

        let activeCategories = productJSON[props.selectedProduct]['active_topic_categories'];
        let fullTopicData = productJSON[props.selectedProduct]['content'][`${props.selectedVersion}-${props.selectedLanguage}`];
        
        activeCategories.forEach(categoryId => {
            if(categoryId in topicData && topicData[categoryId].length){
                docList.push(
                    <ProductDocSection
                        showTitle={right_toc_count.current > 1}
                        key={`ProductDocSection-${categoryId}`}
                        version={props.selectedVersion}
                        categoryId={categoryId}
                        topicList={topicData[categoryId]}
                    />
                )
            }
            else if(categoryId in fullTopicData && fullTopicData[categoryId].length){
                docList.push(
                    <ProductDocSection
                        showTitle={right_toc_count.current > 1}
                        key={`ProductDocSection-${categoryId}`}
                        categoryId={categoryId}
                        topicList={[]}
                    />
                )
            }
        });

        return docList;
    }

    const setScrollState = scroll_state => {
        if(scroll_state === 'on'){
            if(props.rightTocRef && props.rightTocRef.current){
                props.rightTocRef.current.classList.add('no-scroll');
            }
        }
        else{
            if(props.rightTocRef && props.rightTocRef.current){
                props.rightTocRef.current.classList.remove('no-scroll');
            }
        }
    }
 
    const rightTocClickHandler = (event,hashVal) => {
        event.preventDefault();

        const titleElt = document.getElementById(hashVal);
        if(titleElt){
            // setUrlHash(hashVal);
            window.location.hash = hashVal;
            setScrollState('on');
            $('html,body').animate({scrollTop: $(titleElt).offset().top - 250},'200','linear',() => {
                setTimeout(() => setScrollState('off'),200);
            });
        }
    }

    // Generate right toc items
    const generateRightTocItems = () => {
        const tocItems = [];

        if(!props.selectedProduct || !(props.selectedProduct in productJSON)){
            return tocItems;
        }

        if(!props.selectedVersion || !props.selectedLanguage || !(`${props.selectedVersion}-${props.selectedLanguage}` in productJSON[props.selectedProduct]['content'])){
            return tocItems;
        }

        const activeSections = productJSON[props.selectedProduct]['active_topic_categories'];
        const content = productJSON[props.selectedProduct]['content'][`${props.selectedVersion}-${props.selectedLanguage}`];

        if(!activeSections){
            return tocItems;
        }

        activeSections.forEach(categoryId => {
            if(content[categoryId] && content[categoryId].length){
                tocItems.push(
                    <li key={`right-toc-item-${categoryId}`} className={["right-toc-item",selectedCategory ? (selectedCategory === categoryId ? 'active' : '') : ('1' === categoryId ? 'active' : '')].join(' ')}>
                        <a href={`#${topicCategoriesJSON[categoryId]['key']}`} onClick={(event) => rightTocClickHandler(event,topicCategoriesJSON[categoryId]['key'])}>
                            {topicCategoriesJSON[categoryId]['title_long']}
                        </a>
                    </li>
                );
            }
        })

        right_toc_count.current = tocItems.length;
        if(tocItems.length > 1){
            return [<ul className="right-toc-container" ref={props.rightTocRef}>{[...tocItems]}</ul>]
        }
        else{
            return []
        }
    }

    // Escape special characters in string
    // Used to escape search input to construct regex
    function escapeRegex(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    
    return(
        <section className={[right_toc_count.current <= 1 ? 'no-right-toc': ''].join(' ')} id="content-section">

            <div className="topics-container-header" ref={props.contentHeaderRef}>
                <div className="version-dropdown-container">
                    <DropdownVersion 
                        selectedProduct={props.selectedProduct}
                        selectedVersion={props.selectedVersion}
                        id="version-1"
                    /> 
                    <DropdownLang
                        selectedProduct={props.selectedProduct}
                        selectedVersion={props.selectedVersion}
                        selectedLanguage={props.selectedLanguage}
                        id="language-1"
                    />
                    <div className="topic-searchbox-container">
                        <span className="topic-searchbox-label">Filter</span>
                        <span className="topic-search-inputfield">
                            <i>
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.33496 12H5.33496C5.06974 12 4.81539 11.8946 4.62785 11.7071C4.44032 11.5196 4.33496 11.2652 4.33496 11V7.205L0.629961 3.5C0.442167 3.31332 0.336077 3.05979 0.334961 2.795V1C0.334961 0.734784 0.440318 0.48043 0.627854 0.292893C0.81539 0.105357 1.06974 0 1.33496 0H11.335C11.6002 0 11.8545 0.105357 12.0421 0.292893C12.2296 0.48043 12.335 0.734784 12.335 1V2.795C12.3338 3.05979 12.2278 3.31332 12.04 3.5L8.33496 7.205V11C8.33496 11.2652 8.2296 11.5196 8.04207 11.7071C7.85453 11.8946 7.60018 12 7.33496 12ZM1.33496 1V2.795L5.33496 6.795V11H7.33496V6.795L11.335 2.795V1H1.33496Z"/>
                                </svg>
                            </i>
                            <input 
                                id="topic-searchbox" 
                                type="text" 
                                placeholder="Filter titles"
                                value={searchValue} 
                                onChange={(event) => setSearchValue(event.target.value)}
                                autoComplete="off"
                            />
                            <button 
                                title='Clear'
                                className="clear-searchbox-button" 
                                onClick={() => setSearchValue('')} 
                                aria-label="Clear Search"
                            >
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.38381 5L10.5 0.883812L9.61619 0L5.5 4.11619L1.38394 0L0.5 0.883812L4.61619 5L0.5 9.11619L1.38394 10L5.5 5.88381L9.61619 10L10.5 9.11619L6.38381 5Z" fill="#525252"/>
                                </svg>
                            </button>
                        </span>
                    </div>
                </div>
                <span className="topic-searchbox-result">
                {
                    searchValue ?
                    <span>
                        <span className="search-count">{Object.keys(topicData).map(a => topicData[a].length).reduce( (a,b) => a+b )} results</span> for "{searchValue}"
                    </span>
                    : ''
                }
                </span>
            </div>
            <div className="documentation-category-list">
                {generateDocSections()}
            </div>
            {generateRightTocItems()}
        </section>
    )
}

export default ProductContent;