import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';

import './App.css';
import LandingPage from './Pages/LandingPage/LandingPage';
import ProductLandingPage from './Pages/ProductLandingPage/ProductLandingPage';
import SearchPage from './Pages/SearchPage/SearchPage';
import NotFoundPage from './Pages/404/404'; 

import productJSON from './Data/products.json';

function App() {

  const [selectedCapability, setSelectedCapability] = useState('');

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LandingPage selectedCapability={selectedCapability} setSelectedCapability={setSelectedCapability} />,
      loader: ({ request }) => {
        const url = new URL(request.url);
        const q = url.searchParams.get('q');
        if (q === '404') {
          return redirect('/404');
        }
        return null;
      },
    },
    {
      path: '/search',
      element: <SearchPage />,
    },
    // {
    //   path: '/on-premises/webmethods-adapter',
    //   loader: () => redirect('/on-premises/webmethods-adapters'), // Redirect the correct adapter link
    // },
    {
      path: '/on-premises/:productName/:lang?/:version?/index.html?',
      element: <ProductLandingPage selectedCapability={selectedCapability} deployment={'on-premises'}/>,
      loader: async ({ params }) => {
        const deployment = 'on-premises';
        const { productName, lang, version } = params;
        const fullPath = `/${deployment}/${productName}`;

        const validPaths = (Object.keys(productJSON).map(productId => productJSON[productId].baseURL))
        const productCode = (Object.keys(productJSON).filter(productId => productJSON[productId].baseURL === fullPath))[0];

        if(!validPaths.includes(fullPath)) {
          return redirect('/404');
        }
        else if(productCode && lang && version){
          const versions = Object.keys(productJSON[productCode].versions);
          if(version && lang && !versions.includes(`${version}-${lang}`)){
            let langFound = versions[0].split('-')[1]
            let versionFound = versions[0].split('-')[0]
            return redirect(`${fullPath}/${langFound}/${versionFound}`);
          }
        }
        
        return null;
      },
    },
    {
      path: '/saas/:productName/:version?/index.html?',
      element: <ProductLandingPage selectedCapability={selectedCapability} deployment='saas'/>,
      loader: async ({ params }) => {

        // const lang = 'en'
        const deployment = 'saas';
        const lang = 'en';
        const { productName, version } = params;
        const fullPath = `/${deployment}/${productName}`;

        const validPaths = (Object.keys(productJSON).map(productId => productJSON[productId].baseURL))
        const productCode = (Object.keys(productJSON).filter(productId => productJSON[productId].baseURL === fullPath))[0];

        if(!validPaths.includes(fullPath)) {
          return redirect('/404');
        }
        else if(productCode && version){
          const versions = Object.keys(productJSON[productCode].versions);
          if(version && !versions.includes(`${version}-${lang}`)){
            let versionFound = versions[0].split('-')[0]
            return redirect(`${fullPath}/${versionFound}`);
          }
        }
        return null;
      },
    },
    {
      path: '/404', 
      element: <NotFoundPage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  return (
    <RouterProvider router={router}/>
  );
}

export default App;
