import React from "react";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

// import {convertDateFormat, isRecentlyUpdated} from 'utils';

import './TopicCard.css';

const TopicCard = ({ topicObj }) => {

    const sanitizedTitle = DOMPurify.sanitize(topicObj.title);

    return (
        <a 
            href={topicObj.link} 
            target={topicObj.link.startsWith('#') ? "_self" : "_blank" }
            rel={topicObj.link.startsWith('#') ? undefined : "noopener noreferrer"}
            className="topic-card-link" 
            title={`Explore ${parse(sanitizedTitle)}`}
        >
            <div className="topic-card" tabIndex="1" id="1">
                <h4 className="topic-card-title">{parse(sanitizedTitle)}</h4>
                <div className="topic-card-footer">
                    {
                        topicObj.link && topicObj.link.startsWith('http') ?
                        <div className="topic-card-external-link">
                            <span>
                                <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26,28H6a2.0027,2.0027,0,0,1-2-2V6A2.0027,2.0027,0,0,1,6,4H16V6H6V26H26V16h2V26A2.0027,2.0027,0,0,1,26,28Z"/>
                                    <path d="M20 2L20 4 26.586 4 18 12.586 19.414 14 28 5.414 28 12 30 12 30 2 20 2z"/>
                                </svg>
                            </span>
                        </div>:
                        <div className="topic-card-tags">
                            {
                                topicObj.tags.map(tagName => <div key={`topic-tag-${tagName}`} className="tags-item">{tagName}</div>)
                            }
                            {/* {
                                topicObj['last_updated'] && isRecentlyUpdated(topicObj['last_updated'], 30) ?
                                <div key={`topic-tag-last_updated`} className="tags-item colored-tag">Updated {convertDateFormat(topicObj['last_updated'],'MMM-YYYY')}</div>
                                :''
                            } */}
                        </div>
                    }
                </div>
            </div>
        </a>
    )
}

export default TopicCard;
