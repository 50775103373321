import React,{useEffect, useRef} from "react";
import $ from 'jquery';

import './Dropdown.css';

const Dropdown = (props) => {

    const dropdownWrapperRef = useRef(null);

    const dropdownCloseHandler = (event) => {
        const targetElt = $(event.target);
        const isInDropDown = targetElt.closest(`.dropdown-container#${props.id}`).length;
        // const dropdownElt = document.getElementById(props.id);
        const dropdownElt = dropdownWrapperRef.current;
        const isClosed = !dropdownElt.classList.contains('list-box--expanded');
        
        if(!dropdownElt){
            return;
        }

        // Check if click happened within a dropdown
        if(!isInDropDown){
            dropdownElt.classList.remove("list-box--expanded");
        }
        else if(isInDropDown){
            // Close all dropdowns
            $('.dropdown-container').each((idx, elt) => {
                elt.classList.remove("list-box--expanded");
            })

            if(isClosed){
                // Open dropdown if it was previously closed
                dropdownElt.classList.add("list-box--expanded");
            }
        }
    }

    useEffect(() => {
        document.addEventListener("click", dropdownCloseHandler);

        return () => {
            document.removeEventListener("click", dropdownCloseHandler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const generateOptions = () => {
        const options = [];

        props.children.forEach((optElt, idx) => {
            options.push(
                <li
                    key={`${props.title}-${idx}`}
                    className={["list-box__menu-item"].join(" ")}
                >
                    <div className="list-box__menu-item__option">
                        {optElt}
                    </div>
                </li>
            )
        });

        return options;
    }

    return(
        <div className={["dropdown-wrapper",props.children.length > 1 ? '' : 'disabled'].join(' ')}>
            <label 
                className="dropdown-label" 
                id="downshift-:r5:-label" 
                htmlFor="downshift-:r5:-toggle-button"
            >
                {props.title || ''}
            </label>
            <div 
                ref={dropdownWrapperRef} 
                id={props.id}
                className="dropdown-container"
            >
                <button 
                    className="list-box_field"
                    type="button"
                    aria-describedby={props.ariaDescribedby}
                    title={props.selectedTitle}
                    aria-activedescendant="" 
                    aria-controls="downshift-:r5:-menu" 
                    aria-expanded="false" aria-haspopup="listbox" 
                    aria-labelledby="downshif" role="combobox" 
                    tabIndex="0"
                >
                    <span className="selected_option_label">
                        { props.selectedLabel || ''}
                    </span>
                    <div className="list-box__menu-icon">
                        <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" name="chevron--down" aria-label="Open menu" width="16" height="16" viewBox="0 0 16 16" role="img" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path>
                            <title>Open menu</title>
                        </svg>
                    </div>
                </button>
                {
                    props.children.length > 1 ?
                    <ul className="list-box__menu">
                        {generateOptions()}
                    </ul>:''
                }
            </div>
        </div>
    )
}

export default Dropdown;