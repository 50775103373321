import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import $ from 'jquery';

// import {isRecentlyUpdated} from 'utils';

import Dropdown from "../Dropdown/Dropdown";

import './DropdownVersion.css';

import productJSON from '../../Data/products.json';
// import topicsJSON from '../../Data/topics.json';

const DropdownVersion = (props) => {

    const [selectedLabel, setSelectedLabel] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('');
    const [versions, setVersions] = useState([]);
    const [versionList, setVersionList] = useState({});

    const versionSort = (a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || 0; // Default to 0 if undefined
            const bPart = bParts[i] || 0; // Default to 0 if undefined

            if (aPart < bPart) return 1;
            if (aPart > bPart) return -1;
        }

        return 0;
    }

    // const isTopicsUpdated = (version) => {
    //     if(!props.selectedProduct || !(props.selectedProduct in productJSON)){
    //         return false;
    //     }

    //     let isUpdated = false;
    //     const productObj = productJSON[props.selectedProduct];
    //     const active_topics_list = productObj['active_topic_categories'];

    //     Object.keys(productObj['content'])
    //             .filter(x => x.startsWith(version))
    //             .forEach(vl => {
    //                 active_topics_list.forEach(cat_id => {
    //                     if(cat_id in productObj['content'][vl]){
    //                         const topicsList = productObj['content'][vl][cat_id];
    //                         topicsList.forEach(tid => {
    //                             const topicObj = tid in topicsJSON ? topicsJSON[tid] : null;
    //                             if(topicObj){
    //                                 const last_updated = topicObj['last_updated'];
    //                                 if(isRecentlyUpdated(last_updated,30)){
    //                                     isUpdated = true
    //                                 }
    //                             }
    //                         })
    //                     }
    //                 })
    //             });

    //     return isUpdated;
    // }

    useEffect(() => {
        if(props.selectedProduct && props.selectedProduct in productJSON && props.selectedVersion){

            // Updating version variables
            const versionList = productJSON[props.selectedProduct]["versions"]; // Versions object
            setVersionList({...versionList});

            let versions = Object.keys(versionList).map(vl => vl.split('-')[0]) // = ["10.3.0","10.3.0","11.1.0"]
            versions = Array.from(new Set(versions)); // = ["10.3.0","11.1.0"]
            versions = versions.sort(versionSort); // = ["11.1.0", "10.3.0"]
            setVersions([...versions]);

            let versionTitle = props.selectedVersion && props.selectedProduct && props.selectedProduct in productJSON ? productJSON[props.selectedProduct]["version_labels"][props.selectedVersion] : '';
            setSelectedTitle(versionTitle);

            // let versionLabel =  isTopicsUpdated(props.selectedVersion) ? <>{versionTitle} <span className="tags-item colored-tag">Updated</span></> : versionTitle;
            setSelectedLabel(versionTitle);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.selectedProduct, props.selectedVersion]);

    const generateVersionOptions = () => {
        const options = [];
        versions.forEach(v => {
            let optionLabel = props.selectedProduct ? productJSON[props.selectedProduct].version_labels[v] : '';
            let optionLink = `${v}-en` in versionList ? versionList[`${v}-en`] : versionList[`${v}-ja`];
            let isExternalLink = optionLink.endsWith('.html') || optionLink.endsWith('.html');
            let selectedClass = v === props.selectedVersion ? 'selected' : '';
            // let isUpdated = isTopicsUpdated(v);
            let classList = [selectedClass].join(' ');

            if(isExternalLink){
                options.push(
                    <a key={`dropdown-option-${v}`} title={`Version ${optionLabel}`} className={classList} href={optionLink}>
                        {optionLabel}
                        {/* {isUpdated ? <span>test</span> : ''} */}
                    </a>
                )  
            }
            else{
                options.push(
                    <Link key={`dropdown-option-${v}`} title={`Version ${optionLabel}`} className={classList} to={optionLink}>
                        {optionLabel}{/* {isUpdated ? <span>test</span> : ''} */}
                    </Link>
                )
            }
        });
                                
        return options;
    }

    if(versions && versions.length > 1){
        return(
            <Dropdown
                title="Version"
                selectedLabel={selectedLabel}
                selectedTitle={selectedTitle}
                selectedValue={props.selectedVersion}
                id={props.id}
            >
                {generateVersionOptions()}
            </Dropdown>
        )
    }
    else{
        return('');
    }
}

export default DropdownVersion;