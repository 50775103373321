import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
// import $ from 'jquery';

import productJSON from '../../Data/products.json';
import './DropdownLang.css';
import Dropdown from "../Dropdown/Dropdown";

const DropdownLang = (props) => {
    
    const languageLabels = {
        "en":"English",
        "ja":"Japanese"
    }

    const [languages, setLanguages] = useState([]);

    // useEffect to update the list of languages and language-version options offered
    // Runs everytime the selected product / version is changed
    useEffect(() => {
        if(props.selectedProduct && props.selectedProduct in productJSON){
            let langs = [];
            if(props.selectedVersion){
                const langList = Object.keys(productJSON[props.selectedProduct]["versions"])
                                .filter(vl => vl.split('-')[0] === props.selectedVersion)
                                .map(vl => vl.split('-')[1]);

                langs = Array.from(new Set(langList));
            }

            setLanguages([...langs]);
        }

    },[props.selectedProduct, props.selectedVersion]);

    // Function to generate a dropdown option element for each language offered
    const generateLanguageOptions = () => {

        if(!props.selectedProduct || !(props.selectedProduct in productJSON)){
            return [];
        }

        const versionList = productJSON[props.selectedProduct]["versions"]; // Versions object
        const options = [];

        languages.sort().forEach(l => {
            const optionLabel = languageLabels[l];
            const optionLink = `${props.selectedVersion}-${l}` in versionList ? versionList[`${props.selectedVersion}-${l}`] : '#';
            const isExternalLink = optionLink.endsWith('.html') || optionLink.endsWith('.pdf');
            const classList = l === props.selectedLanguage ? 'selected' : '';
            
            if(isExternalLink){
                options.push(<a title={optionLabel} className={classList} href={optionLink}>{optionLabel}</a>);
            }
            else{
                options.push(<Link title={optionLabel} className={classList} to={optionLink}>{optionLabel}</Link>);
            }
        });

        return options;
    }
    
    // Show version picker when:
    // 1. The only language offered is Japanese
    // 2. There are more than one languages offered
    if(languages && (languages.length > 1 || languages.includes('ja')) ){
        return(
            <Dropdown
                title="Language"
                selectedLabel={languageLabels[props.selectedLanguage]}
                selectedValue={props.selectedLanguage}
                id={props.id}
            >
                {generateLanguageOptions()}
            </Dropdown>
        );
    }
    else{
        return('');
    }
}

export default DropdownLang;