import React from "react";

import './ProductDocSection.css';

import TopicCard from "../TopicCard/TopicCard";

import topicsJSON from '../../Data/topics.json';
import topicCategoriesJSON from '../../Data/topic-categories.json';

const ProductDoctSection = (props) => {

    // Generate topic cards
    const generateTopicCardElts = () => {
        const topicCardList = [];
        if(!props.topicList){ return; }

        props.topicList.forEach(topicId => {
            const topicObj = topicsJSON[topicId];
            topicCardList.push(
            <li key={`${props.categoryId}-${topicId}`} >
                <TopicCard
                    topicObj={topicObj}
                    parentProduct={props.selectedProduct}
                />
            </li>);
        });

        return [...topicCardList];
    }

    return(
        <div className="documentation-category">
            <span className="seperator-wrapper">
                <hr/>
            </span> 
            {
                props.categoryId && props.categoryId in topicCategoriesJSON && props.showTitle ? 
                <h3 id={topicCategoriesJSON[props.categoryId]['key']} className="documentation-category-title">{topicCategoriesJSON[props.categoryId]['title_long']}</h3> 
                : ''
            }
            <ul className={props.topicList.length ? '' : 'empty'}>
                {
                    props.topicList.length ?
                    generateTopicCardElts() 
                    : <span className="empty-text">No results found.</span>
                }
            </ul>
        </div>
    )
}

export default ProductDoctSection;