import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery'; 
import './Feedback.css';

// Image sources stored as variables
import yesFeedbackDefaultSrc from './yes-feedback-default.svg';
import yesFeedbackActiveSrc from './yes-feedback-active.svg';
import partlyFeedbackDefaultSrc from './partly-feedback-default.svg';
import partlyFeedbackActiveSrc from './partly-feedback-active.svg';
import noFeedbackDefaultSrc from './no-feedback-default.svg';
import noFeedbackActiveSrc from './no-feedback-active.svg';
import invalidIcon from '../../assets/images/invalid-icon.svg';

const Feedback = () => {

  const [scrollPosition, setScrollPosition] = useState(0);

  const [formData, setFormData] = useState({
    response: '',
    feedbackOption: '',
    copiedText: '',
    comments: '',
    email: '',
  });

  const [formValid, setFormValid] = useState(false);

  const [isFormVisible, setIsFormVisible] = useState(false);
  // const [isMobileFormVisible, setIsMobileFormVisible] = useState(false);
  const [isSpecificContentSelected, setSpecificContentSelected] = useState(false);

  const loadingDivRef = useRef();
  const mobileLoadingDivRef = useRef();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    // setIsMobileFormVisible(!setIsMobileFormVisible);
  };

  const toggleMobileFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    // setIsMobileFormVisible(!setIsMobileFormVisible);
  };

  const clearFormData = () => {
    setFormData({
      response: '',
      feedbackOption: '',
      copiedText: '',
      comments: '',
      email: '',
    });
    setSpecificContentSelected(false); 
  };

  const handleFormSubmit = (event) => {
        event.preventDefault();
        
        const {
            response,
            feedbackOption,
            copiedText,
            comments,
            email,
        } = formData;

        
        
        const pageURL = window.location.href;
        const product = window.location.href.split('/')[4];
        // const article_title = 'test article title'
        // console.log(product)
       
        // const device = isMobileFormVisible ? 'Smaller screen' : 'Larger screen';
        function deviceType()  {
          const ua = navigator.userAgent;
          if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
              return "tablet";
          }
          else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
              return "mobile";
          }
          return "desktop";
        };

        $.ajax({
            type: "POST",
            url: 'https://docfeedback.int-aws-us.webmethods.io/runflow/run/sync/v2/18dBBuKsSm',
            data: {
              article_title: 'article_title',
              response: response,
              feedbackoption: feedbackOption ||'N/A' ,
              copiedtext: copiedText || 'N/A',
              comments: comments || 'N/A', 
              device: `${deviceType()} | width ${window.screen.width}px`,
              email: email || 'N/A',
              product: product,
              pageURL: pageURL
            },
            success: function (res) {
                
               clearFormData();
               
               if (mobileLoadingDivRef.current){
                  mobileLoadingDivRef.current.innerHTML = "<span class='container alert alert-success'>Thank you! We value your feedback.</span>";
               }

               if(loadingDivRef.current){
                loadingDivRef.current.innerHTML = "<span class='container alert alert-success'>Thank you! We value your feedback.</span>";
               }

               setTimeout(function () {
                 document.getElementById('loading').innerHTML = "";
                 setIsFormVisible(false);
                //  setIsMobileFormVisible(false); 
               }, 2000); 
             }
        });
  };

  // Effect to capture selected text when specific content is selected
  useEffect(() => {
      const handleMouseUp = () => {
      if (formData.feedbackOption === 'specific') {
          const selectedText = window.getSelection().toString();
          if (selectedText) {
          setFormData((prevData) => ({
              ...prevData,
              copiedText: selectedText,
          }));
          setSpecificContentSelected(true);
          }
      }
      };

      document.addEventListener('mouseup', handleMouseUp);

      return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      };
  }, [formData.feedbackOption]);

  const clearText = () => {
      setFormData((prevData) => ({
      ...prevData,
      copiedText: '',
      }));
      setSpecificContentSelected(false);
  };

  const updateFormValidty = () => {
    // For checking individual field validity
    
    // For checking complete form validity
    let validity = true;
    
    const {
        response,
        feedbackOption,
        copiedText,
        comments
    } = formData;
    
    // Check if response of yes/no/partly is not selected
    if(!response){
      validity = false;
    }
    
    // Check if feedback option is not selected
    if(response !== 'yes'){
      if(!feedbackOption){
        validity = false;
      }
      else if(feedbackOption === 'specific' && !copiedText){
        validity = false;
      }
    }
    
    // Check if comments are given
    if(validity && response !== 'yes' && !comments){
      validity = false;
    }
    
    setFormValid(validity);
  }

  // Use effect that updates the form validity whenever the input changes
  useEffect(updateFormValidty,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [formData]);

  const topBtnRef = useRef();
  const feedbackFormRef = useRef();
  const feedbackTabRef = useRef();
  const mobFeedbackFormRef = useRef();
  const mobFeedbackTabRef = useRef();

  //use effect to scroll to top
  useEffect(() => {
    const handleScroll = () => {

      // setIsFormVisible(false);
      // setIsMobileFormVisible(false);
      setScrollPosition($(window).scrollTop());

      if ($(window).scrollTop() <= 80 && topBtnRef.current && !$(topBtnRef.current).is(":hidden")) {

        if(!topBtnRef.current) return;
        
        $(topBtnRef.current).hide(0, () => {

          if(feedbackTabRef.current){
            $(feedbackTabRef.current).stop().animate({ bottom: "24px" });
            // $(feedbackTabRef.current).stop().animate({ bottom: "-=24px" });
          }
          
          if(mobFeedbackTabRef.current){
            $(mobFeedbackTabRef.current).stop().animate({ bottom: "24px" });
            // $(mobFeedbackTabRef.current).stop().animate({ bottom: "-=34px" });
          }
        });

      } else if ($(window).scrollTop() > 80 && topBtnRef.current && $(topBtnRef.current).is(":hidden")) {
        
        topBtnRef.current && $(topBtnRef.current).show();
        feedbackTabRef.current && $(feedbackTabRef.current).stop().animate({ bottom: "60px" }, 50);
        // if(feedbackTabRef.current){
        //   $(feedbackTabRef.current).stop().animate({ bottom: "60px" }, 50, () => {
        //     topBtnRef.current && $(topBtnRef.current).show();
        //     console.log(feedbackFormRef.current);
        //     feedbackFormRef.current && $(feedbackFormRef.current).stop().animate({ bottom: "120px" });
        //   });
        // }
  
        mobFeedbackTabRef.current && $(mobFeedbackTabRef.current).stop().animate({ bottom: "60px" }, 50);
        // if(mobFeedbackTabRef.current){
        //   $(mobFeedbackTabRef.current).stop().animate({ bottom: "60px" }, 50, () => {
        //     topBtnRef.current && $(topBtnRef.current).show();
        //     mobFeedbackFormRef.current && $(mobFeedbackFormRef).stop().animate({ bottom: "+=34px" });
        //   });
        // }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <div>
      <span
        ref={feedbackTabRef}
        className={[
          `feedback feedback-icon`,
          isFormVisible ? 'down-arrow' : '',
          Object.keys(formData).filter(key => formData[key]).length ? 'feedback-icon-active' : ''
        ].join(' ')}
        id="feedback-tab"
        title="Provide feedback"
        onClick={toggleFormVisibility}
      ></span>

      <span
        ref={mobFeedbackTabRef}
        className={[
          `feedback feedback-icon`,
          isFormVisible ? 'down-arrow' : '',
          Object.keys(formData).filter(key => formData[key]).length ? 'feedback-icon-active' : ''
        ].join(' ')}
        id="mob_feedback-tab"
        title="Provide feedback"
        onClick={toggleMobileFormVisibility}
      ></span>

      {/* Feedback Form */}
      {isFormVisible && (
        <form 
          ref={feedbackFormRef} 
          id="feedback-form" 
          onSubmit={handleFormSubmit}
          style={{'marginBottom':`${scrollPosition > 80 ? 36 : 0}px`}}
        >
          <div className="feedback-form-inner">
            <div className="formheader d-flex container mt-5">
              <span>Did you find the content helpful?</span>
            </div>
            <div className="feedback-radio-container">
              <div className="response-option-item">
                <input
                  type="radio"
                  id="yes-radio-btn"
                  name="response"
                  value="yes"
                  onChange={handleInputChange}
                  required
                  checked={formData.response === "yes" && true}
                />
                <label className="response-option-label" htmlFor="yes-radio-btn">
                  <img
                    className="response-default no-zoom"
                    src={yesFeedbackDefaultSrc}
                    alt=":)"
                  />
                  <img
                    className="response-active no-zoom"
                    src={yesFeedbackActiveSrc}
                    alt=":)"
                  />
                  <span>Yes</span>
                </label>
              </div>
              <div className="response-option-item">
                <input
                  type="radio"
                  id="partly-radio-btn"
                  name="response"
                  value="partly"
                  onChange={handleInputChange}
                  checked={formData.response === "partly" && true}
                />
                <label className="response-option-label" htmlFor="partly-radio-btn">
                  <img
                    className="response-default no-zoom"
                    src={partlyFeedbackDefaultSrc}
                    alt=":|"
                  />
                  <img
                    className="response-active no-zoom"
                    src={partlyFeedbackActiveSrc}
                    alt=":|"
                  />
                  <span>Partly</span>
                </label>
              </div>
              <div className="response-option-item">
                <input
                  type="radio"
                  id="no-radio-btn"
                  name="response"
                  value="no"
                  onChange={handleInputChange}
                  checked={formData.response === "no" && true}
                />
                <label className="response-option-label" htmlFor="no-radio-btn">
                  <img
                    className="response-default no-zoom"
                    src={noFeedbackDefaultSrc}
                    alt=":("
                  />
                  <img
                    className="response-active no-zoom"
                    src={noFeedbackActiveSrc}
                    alt=":("
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
            <div 
              className={[
                "feedbackoptions",
                "container",
                "mt-3",
                (formData.response === 'no' || formData.response === 'partly') && !formData.feedbackOption ? 'invalid' : '',
              ].join(' ')}
              data-required-message="* This is a required field"
              data-invalid-message="This is a required field"
              style={{'--icon-invalid':`url('${invalidIcon}')`}}
            >
              <span>I have feedback on:</span>
              <div className="mt-2 mb-2">
                <div 
                  className="form-check form-check-inline" 
                >
                  <div className='radio-input-wrapper'>
                    <input
                      type="radio"
                      name="feedbackOption"
                      value="article"
                      className="form-check-input"
                      id="article"
                      onChange={handleInputChange}
                      checked={formData.feedbackOption === "article" && true}
                      required={formData.response === '' || formData.response === 'yes' ? false : true}
                    />
                    <label htmlFor="article" className="form-check-label">
                      This page or site
                    </label>
                  </div>
                  <div className='radio-input-wrapper'>
                    <input
                      type="radio"
                      name="feedbackOption"
                      value="specific"
                      id="specific"
                      className="form-check-input ml-2"
                      checked={formData.feedbackOption === "specific" && true}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="specific" className="form-check-label">
                      Specific content
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* Conditionally render the selected text container */}
            {formData.feedbackOption === 'specific' && (
              <div id="selected-text-container">
                <div id="guidetext" className="mt-2">
                  {formData.copiedText ? formData.copiedText : 'Please select the text of concern to enter your comments' }
                </div>
                <div
                  id="copiedText"
                  name="copiedText"
                  value={formData.copiedText}
                  placeholder="Please select the text on this page and enter your comments"
                  className="form-control"
                  disabled
                  onChange={handleInputChange}
                ></div>
                {isSpecificContentSelected && (
                  <button href="#" onClick={clearText} id="clearlink">
                    Clear Selection
                  </button>
                )}
              </div>
            )}

            <div className="form-group comments-group">
              <textarea
                id="comments"
                name="comments"
                rows="10"
                cols="35"
                placeholder="Enter your comments here"
                className="form-control"
                maxLength={2000}
                onChange={handleInputChange}
                value={formData.comments}
                required={formData.response === '' || formData.response === 'yes' ? false : true}
                disabled={formData.feedbackOption ? false : true}
              ></textarea>
              <label 
                htmlFor="comments"
                data-invalid-message="* This is a required field"
                style={{'--icon-invalid':`url('${invalidIcon}')`}}
              >
                <span>Comments</span>
                <span id="charNum">{formData.comments.length}/{2000}</span></label>
            </div>

            <div className="email-group">
              <input
                type="email"
                id="userEmail"
                className="form-control"
                placeholder="Your email address"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                // disabled="disabled"
              />
              <label 
                htmlFor="userEmail"
                style={{'--icon-invalid':`url('${invalidIcon}')`}}
                data-invalid-message="Please enter a valid email address"
              >Email (Optional)</label>
            </div>

            <div id="loading" ref={loadingDivRef}></div>

            <div className="form-submit-group">
              {/* <span
                // href="https://www.softwareag.com/en_corporate/privacy.html" 
                id="privacyPolicy2" 
                target="_blank">
                Privacy policy
                <span className="divClass">
                  <span id="tooltip_title">Privacy policy</span>
                  <span id="privacy_tooltip">
                    The information collected from this form will be used solely to improve the
                    documentation. Your email address will be used only to respond to your feedback. Click the link to view
                    the complete privacy policy.
                  </span>
                </span>
              </span> */}
              <button type="submit" id="submit" disabled={!formValid}>
                Submit
              </button>
              <button type="reset" id="cancel" onClick={() => {toggleFormVisibility(); clearFormData();}}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}

      {/* Mobile Feedback Form */}
        {isFormVisible && (
        <form 
          ref={mobFeedbackFormRef} 
          id="mob_feedbackform" 
          onSubmit={handleFormSubmit}
          style={{'marginBottom':`${scrollPosition > 80 ? 40 : 0}px`}}
        >
            <div className="mob_Feedback_title mob_container">
            <span>Did you find the content helpful?</span>
            <button className="mob_Feedback_minimizeBtn" onClick={toggleMobileFormVisibility}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 16 16" width="24" height="24">
                <title>minimize feedback</title>
                <path d="M9 8.25l1.2-1.25 4.8 5-4.8 5-1.2-1.25 3.6-3.75-3.6-3.75z"></path>
                </svg>
            </button>
            </div>
            
            <div className="mob_container">
            <div className="d-flex mt-2">
                <div className="response-option-item">
                <input
                    type="radio"
                    id="mob_yes-radio-btn"
                    name="response"
                    value="yes"
                    onChange={handleInputChange}
                    checked={formData.response === "yes" && true}
                />
                <label className="response-option-label" htmlFor="mob_yes-radio-btn">
                    <img
                    className="response-default no-zoom"
                    src={yesFeedbackDefaultSrc}
                    alt=":)"
                    />
                    <img
                    className="response-active no-zoom"
                    src={yesFeedbackActiveSrc}
                    alt=":)"
                    />
                    <span>Yes</span>
                </label>
                </div>

                <div className="response-option-item">
                <input
                    type="radio"
                    id="mob_no-radio-btn"
                    name="response"
                    value="no"
                    onChange={handleInputChange}
                    checked={formData.response === "no" && true}
                    required
                />
                <label className="response-option-label" htmlFor="mob_no-radio-btn">
                    <img
                    className="response-default no-zoom"
                    src={noFeedbackDefaultSrc}
                    alt=":("
                    />
                    <img
                    className="response-active no-zoom"
                    src={noFeedbackActiveSrc}
                    alt=":("
                    />
                    <span>No</span>
                </label>
                </div>
            </div>

            <div className="mob-form-group">
                <label className="mob_comments_label" htmlFor="mob_comments">
                Comments
                </label>
                <textarea
                id="mob_comments"
                name="comments"
                rows="5"
                cols="35"
                placeholder="Enter your comments here."
                className="mob-form-control"
                maxLength={2000}
                value={formData.comments}
                onChange={handleInputChange}
                required={formData.response==='no'? true : false}
                ></textarea>
            </div>
            <div id="loading"ref={mobileLoadingDivRef}></div>
            <div className="mob_form-btn-container">
                <button type="reset" id="mob_cancel" onClick={toggleMobileFormVisibility}>
                Cancel
                </button>
                <button type="submit" id="mob_submit">
                Submit
                </button>
            </div>
            </div>
        </form>
      )}

    <button
      ref={topBtnRef}
      title="Scroll to top"
      type="button"
      className="top-btn"
      onClick={() => {
        $('body, html').animate({ scrollTop: 0 }, 500, 'swing');
      }}
    >
      TOP
    </button>
    </div>
    
  );

};

export default Feedback;
