import React,{ useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom'
import $ from 'jquery';

import NavBar from "../../Components/NavBar/NavBar";
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import AnnouncementBanner from '../../Components/AnnouncementBanner/AnnouncementBanner';

import ProductTitle from "../../Components/ProductTitle/ProductTitle";
import ProductContent from "../../Components/ProductContent/ProductContent";
import Feedback from "../../Components/Feedback/Feedback";
import Footer from "../../Components/Footer/Footer";

import './ProductLandingPage.css';

import productJSON from '../../Data/products.json';
// import capabilityJSON from '../../Data/capabilities.json';
import tocJSON from '../../Data/toc.json';
import bannerJSON from '../../Data/banners.json';

const ProductLandingPage = ({selectedCapability, deployment}) => {
    const params = useParams();
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedVersion, setSelectedVersion] = useState(params.version);
    const [selectedLanguage, setSelectedLanguage] = useState(params.lang ? params.lang : 'en');
    const [showBanner, setShowBanner] = useState(false);

    const bannerKey = useRef('');
    const breadcrumbWrapperRef = useRef(null);
    const contentHeaderRef = useRef(null);
    const rightTocRef = useRef(null);
    const announcementBannerRef = useRef(null);

    const deploymentLabels = {'On-premises':'Software', 'SaaS': 'SaaS'};

    const versionSort = (a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || 0; // Default to 0 if undefined
            const bPart = bParts[i] || 0; // Default to 0 if undefined

            if (aPart < bPart) return 1;
            if (aPart > bPart) return -1;
        }

        return 0;
    }

    // Top offset configuration
    const setTopOffset = () => {
        if(breadcrumbWrapperRef && breadcrumbWrapperRef.current){
            if(contentHeaderRef && contentHeaderRef.current && rightTocRef && rightTocRef.current){
                const breadcrumbsOffset = breadcrumbWrapperRef.current.querySelector('.breadcrumb-list').getBoundingClientRect().bottom;
                const headerOffset = contentHeaderRef.current.getBoundingClientRect().bottom;
                contentHeaderRef.current.style.top = `${breadcrumbsOffset}px`;
                rightTocRef.current.style.top = `${headerOffset}px`;
            }
        }
    }

    useEffect(() => {
        const searchInput = document.querySelector('#gsc-i-id1');
        if (searchInput) {
            searchInput.setAttribute('placeholder', 'Search the entire site'); 
        }
    },[]);

    useEffect(() => {
        
        window.scrollTo(0,0);

        const currentURL = `/${deployment}/${params.productName}`;
        const productCodeList = Object.keys(productJSON).filter(idx => productJSON[idx].baseURL === currentURL);
        const curr_product_code = productCodeList && productCodeList.length ? productCodeList[0] : null;
        let curr_version = 'None'
        let curr_language  = 'en'

        if(curr_product_code){

            // setting the selected version
            const versions = Object.keys(productJSON[curr_product_code].versions)
                                .map(vl => vl.split('-')[0]);

            versions.sort((a,b) => versionSort(a,b));
            
            if(params.version){
                curr_version = params.version;
            }
            else if(versions.length){
                if(!params.version){
                    curr_version = versions[0];
                }
                else if(!(params.version in versions)){
                    curr_version = versions[0];
                }
            }

            // setting the selected language
            if(params.lang){
                curr_language = params.lang;
            }

            // setting the banner state
            let banner_key = `${curr_product_code}-${curr_version}-${curr_language}`;
            let bannerBool = banner_key in bannerJSON; // Checking banner availability

            if(!bannerBool){
                banner_key = `${curr_product_code}-All-${curr_language}`;
                bannerBool = banner_key in bannerJSON;
            }

            if(bannerBool){
                const closeCount = Number(bannerJSON[banner_key].close_count); // get the max close count
                if(closeCount){
                    // if close count is set, check if the number of times the banner was closed is lesser than close count
                    const currentCount = Number(window.localStorage.getItem(`${window.location.pathname}`));
                    if(currentCount < closeCount){
                        window.localStorage.setItem(`${window.location.pathname}`,currentCount);
                    }
                    else{
                        bannerBool = false;
                    }
                }
            }

            // Setting state variables
            setSelectedProduct(curr_product_code);
            setSelectedVersion(curr_version);
            setSelectedLanguage(curr_language);
            setShowBanner(bannerBool);
            bannerKey.current = banner_key;
        }

        window.addEventListener("resize", setTopOffset);
        window.addEventListener("scroll", setTopOffset);

        // Remove event listeners on unmount
        return () => {
            window.removeEventListener("resize",setTopOffset);
            window.removeEventListener("scroll",setTopOffset);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[params]);

    const getParentToc = () => {
        
        if(selectedCapability){
            return {"text": tocJSON[selectedCapability].title, "link":`/#${tocJSON[selectedCapability].key}`};
        }
        else if(selectedProduct){
            const parent_tid = Object.keys(tocJSON).filter(tid => tocJSON[tid]["capability_mapping"].filter(cid => productJSON[selectedProduct].capabilityId.includes(cid)).length)[0];
            if(parent_tid){
                return {"text": tocJSON[parent_tid].title, "link":`/#${tocJSON[parent_tid].key}`};
            }
            else{
                return {};
            }
        }

        return {};
    }

    const bannerCloseHandler = () => {
        let bannerBool = selectedProduct && selectedProduct in bannerJSON;    

        if(bannerBool){
            const closeCount = Number(bannerJSON[selectedProduct].close_count); // get the max close count
            const newCount = Number(window.localStorage.getItem(`${window.location.pathname}`)) + 1;
            window.localStorage.setItem(`${window.location.pathname}`,Math.min(closeCount, newCount));
        }

        if(announcementBannerRef.current){
            $(announcementBannerRef.current).slideUp();
        }

        setTimeout(() => {
            setShowBanner(false);
        },300);
    }

    return(
        <React.Fragment>
            <NavBar/>
            {
                showBanner ?
                <AnnouncementBanner 
                    data={bannerJSON[bannerKey.current]} 
                    bannerCloseHandler={bannerCloseHandler}
                    announcementBannerRef={announcementBannerRef}
                />
                :''
            }
            {
                selectedProduct && selectedProduct in productJSON ?
                <Breadcrumbs
                    data={[
                        {"text":"Home", "link":"/"},
                        {...getParentToc()},
                        {"text": deploymentLabels[productJSON[selectedProduct].deployment], "link":`/#${selectedCapability ? tocJSON[selectedCapability].key : tocJSON[productJSON[selectedProduct].capabilityId[0]].key}${productJSON[selectedProduct].deployment === 'SaaS' ? '-saas':''}`},
                        {"text":`${productJSON[selectedProduct].title}${selectedProduct && selectedVersion && selectedVersion !== "None" ? ` ${productJSON[selectedProduct].version_labels[selectedVersion]}` : ''}`, "link":productJSON[selectedProduct]['product_card_link']}
                    ]}
                    breadcrumbWrapperRef={breadcrumbWrapperRef}
                />:
                ''
            }
            <section className="product-landing-wrapper">
                <ProductTitle 
                    title={selectedProduct in productJSON ? productJSON[selectedProduct].title : ''}
                    version={selectedProduct && selectedVersion && selectedVersion !== "None" ? productJSON[selectedProduct].version_labels[selectedVersion] : ''}
                />
                <ProductContent 
                    setTopOffset={setTopOffset}
                    contentHeaderRef={contentHeaderRef} rightTocRef={rightTocRef}
                    selectedProduct={selectedProduct} selectedVersion={selectedVersion} selectedLanguage={selectedLanguage}
                    baseURL={selectedProduct in productJSON ? productJSON[selectedProduct].baseURL : ''}/>
            </section>
            <Feedback />
            <Footer/>
        </React.Fragment>
    )
}

export default ProductLandingPage;