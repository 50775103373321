import React from "react";

import './ProductTitle.css';
import bannerImage from '../../assets/images/background.png'


const ProductTitle = ({ title, version }) => {

    return(
        <section id="product-title-section" style={{backgroundImage:`url(${bannerImage})`}}>
            <h1 id="product-title">
                {title} {version}
            </h1>
        </section>
    )
}

export default ProductTitle;